import React from 'react';

const LogoCard = ({ logo }) => {
  return (
    <img
      src={logo.imagePath}
      alt={logo.alt}
      className="max-h-12 w-full object-contain"
      height={48}
    />
  );
};

export default LogoCard; 