import React from 'react'

const DeckCard = ({ deck }) => {

  if (!deck) {
    console.warn('DeckCard: No deck prop received')
    return null
  }

  return (
    <div className="card">
      <div 
        className="card__content text-center relative transition-transform duration-1000"
        style={{ width: '358px', height: '453px' }}
      >
        <div className="card__front absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center">
          <img src={deck.imagePath} alt={deck.title} className="h-auto rounded-lg" />
        </div>
        <div 
          className="card__back absolute top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center p-8 rounded-3xl"
          style={{ color: deck.textColor, backgroundColor: deck.cardBg }}
        >
          <h2 className="font-bold text-2xl mb-4">{deck.title}</h2>
          <p>{deck.description}</p>
        </div>
      </div>
    </div>
  )
}

export default DeckCard
