import { Controller } from "@hotwired/stimulus";
import React from "react";
import { createRoot } from "react-dom/client";
import DeckCarousel from "../components/DeckCarousel";

export default class extends Controller {
  static values = {
    decks: Array,
  };

  connect() {
    // Parse the JSON string manually if needed
    const decksData = JSON.parse(this.element.dataset.decksValue);

    const root = createRoot(this.element);
    root.render(<DeckCarousel decks={decksData} />);
  }
}
