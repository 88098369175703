import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["text1", "text2", "text3", "text4", "text5"]
  
  connect() {
    console.log("Text rotator connected")
    console.log("Available targets:", this.text1Target, this.text2Target, this.text3Target)
    this.currentIndex = 0
    this.rotateText()
  }

  rotateText() {
    // Create array of all text targets
    const allTexts = [
      this.text1Target,
      this.text2Target,
      this.text3Target
    ]

    // Hide all texts
    allTexts.forEach(text => {
      text.classList.remove('fade-in')
      text.classList.add('fade-out')
    })

    // Show current text
    const currentText = allTexts[this.currentIndex]
    currentText.classList.remove('fade-out')
    currentText.classList.add('fade-in')

    // Increment index
    this.currentIndex = (this.currentIndex + 1) % allTexts.length

    // Schedule next rotation
    setTimeout(() => this.rotateText(), 4000)
  }
} 