import { Controller } from "@hotwired/stimulus"
import React from 'react'
import { createRoot } from "react-dom/client"
import LogoCarousel from "../components/LogoCarousel"

export default class extends Controller {
  static values = {
    logos: Array,
    rtl: Boolean
  }

  connect() {
    const logosData = JSON.parse(this.element.dataset.logoCarouselLogosValue)
    const rtl = this.hasRtlValue ? this.rtlValue : false
    const root = createRoot(this.element)
    root.render(
      <LogoCarousel logos={logosData} rtl={rtl} />
    )
  }
} 