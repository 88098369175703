import Sortable from 'sortablejs';

$(document).on('turbolinks:load', function(){
  var el = document.getElementById('sortable');
  if (el) { // Check if the element exists
    var sortable = Sortable.create(el, { 
      handle: '.item', 
      animation: 150,

      onUpdate: function (evt) {
        var listId = $('#sortable').data('id')

        var orderLists = []
        $('#sortable .item').each(function(i, e) {
          orderLists.push($(e).data('id'))
        })

        $.ajax({
          url: '/lists/' + listId,
          type: 'PUT',
          data: { ids: orderLists }
        });
      }, 
    });
  }
})
