import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LogoCard from "./LogoCard";

const LogoCarousel = ({ logos, rtl = false }) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 3,
      slidesToSlide: 1,
    }
  };

  const carouselItems = logos.map((logo, index) => (
    <div key={index} className="px-4">
      <LogoCard logo={logo} />
    </div>
  ));

  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={3000}
      infinite={true}
      arrows={false}
      showDots={false}
      rtl={rtl}
      containerClass="logo-carousel-container"
      customTransition="transform 3000ms linear"
      transitionDuration={1500}
    >
      {carouselItems}
    </Carousel>
  );
};

export default LogoCarousel; 