import React, { useEffect, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DeckCard from "./DeckCard";

const DeckCarousel = ({ decks }) => {

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const carouselItems = decks.map((deck, index) => (
    <div
      key={index}
      className="carousel-item-wrapper"
      style={{
        flex: "0 0 auto",
        width: "33.333%", // For 3 items
        padding: "0 10px",
      }}
    >
      <DeckCard deck={deck} />
    </div>
  ));

  return (
    <div className="w-full" style={{ minHeight: "" }}>
      <div>
        <Carousel 
        responsive={responsive}
        showDots={true}
        autoPlaySpeed={3000}
        autoPlay={true}
        infinite={true}
        dotListClass="usb-deck-carousel-dots"
        arrows={false}
        renderDotsOutside={true}
        >
            {carouselItems}
        </Carousel>
      </div>
    </div>
  );
};

export default DeckCarousel;
