import { Controller } from "@hotwired/stimulus"
import voiceStoppedSvg from '../images/voice-stopped.svg'
import voiceRecordingSvg from '../images/voice-recording.svg'

export default class extends Controller {
  static targets = ["form", "submit", "recordButton", "microphoneButton", "recordButtonContainer", "submitButtonText", "submitSpinner", "processingText"]

  connect() {
    this.mediaRecorder = null
    this.audioChunks = []
    this.isRecording = false
    this.recordingStatus = document.getElementById('recordingStatus')
    this.transcriptionStatus = document.getElementById('transcriptionStatus')
    this.voiceStoppedSrc = voiceStoppedSvg
    this.voiceRecordingSrc = voiceRecordingSvg
  }

  async toggleRecording() {
    console.log('toggleRecording called, current isRecording:', this.isRecording)
    if (this.isRecording) {
      this.recordButtonTarget.src = this.recordButtonTarget.dataset.stoppedSrc || this.voiceStoppedSrc
      await this.stopRecording()
    } else {
      this.recordButtonTarget.src = this.recordButtonTarget.dataset.recordingSrc || this.voiceRecordingSrc
      await this.startRecording()
    }
    this.isRecording = !this.isRecording
  }

  async startRecording() {
    console.log('startRecording called')
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      console.log('Got media stream:', stream)
      this.mediaRecorder = new MediaRecorder(stream)
      this.audioChunks = []

      this.mediaRecorder.ondataavailable = (event) => {
        console.log('Data chunk received:', event.data)
        this.audioChunks.push(event.data)
      }

      this.mediaRecorder.onstop = async () => {
        console.log('MediaRecorder stopped, chunks collected:', this.audioChunks.length)
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' })
        console.log('Audio blob created:', audioBlob.size, 'bytes')
        await this.transcribeAudio(audioBlob)
        this.recordButtonTarget.style.display = 'none'
      }

      this.mediaRecorder.start()
      console.log('MediaRecorder started')
      this.recordingStatus.textContent = 'Talk Now... Click to stop'
      this.submitTarget.classList.add('hidden')
      this.formTarget.querySelector('textarea[name="conversation[user_brief]"]').classList.add('hidden')
      this.formTarget.querySelector('label[for="conversation_user_brief"]').classList.add('hidden')
    } catch (error) {
      console.error('Error accessing microphone:', error)
      alert('Unable to access microphone. Please ensure you have granted permission.')
    }
  }

  async stopRecording() {
    console.log('stopRecording called')
    this.mediaRecorder.stop()
    console.log('MediaRecorder stopped')
    this.mediaRecorder.stream.getTracks().forEach(track => {
      console.log('Stopping track:', track.kind)
      track.stop()
    })
    this.recordingStatus.textContent = 'Click to start recording'
    console.log('Recording stopped completely')
  }

  async transcribeAudio(audioBlob) {
    console.log('Starting transcription for blob:', audioBlob.size, 'bytes')
    this.transcriptionStatus.classList.remove('hidden')
    this.recordButtonTarget.parentElement.classList.add('opacity-0')
    setTimeout(() => {
      this.recordButtonTarget.parentElement.classList.add('hidden')
    }, 300)
    this.recordingStatus.classList.add('hidden')
    document.getElementById('textRotator').classList.add('hidden')
    
    const formData = new FormData()
    formData.append('audio', audioBlob)

    try {
      console.log('Sending transcription request')
      const response = await fetch('/api/transcribe', {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })

      if (!response.ok) throw new Error('Transcription failed')
      
      const { text } = await response.json()
      console.log('Transcription received:', text)
      
      this.formTarget.classList.remove('hidden')
      this.submitTarget.classList.remove('hidden')
      this.formTarget.querySelector('textarea[name="conversation[user_brief]"]').classList.remove('hidden')
      this.formTarget.querySelector('label[for="conversation_user_brief"]').classList.remove('hidden')
      this.formTarget.querySelector('textarea[name="conversation[user_brief]"]').value = text
      this.formTarget.querySelector('label[for="conversation_user_brief"]').textContent = "Any edits?"
      this.submitTarget.disabled = false
      this.transcriptionStatus.classList.add('hidden')
    } catch (error) {
      console.error('Transcription error:', error)
      this.transcriptionStatus.textContent = 'Error transcribing audio'
    }
  }

  showRecordButton() {
    this.recordButtonContainerTarget.classList.remove('hidden', 'opacity-0')
    this.microphoneButtonTarget.classList.add('hidden', 'opacity-0')
    this.toggleRecording()
  }

  submit(event) {
    event.preventDefault();
    
    // Disable submit button and show spinner
    this.submitTarget.disabled = true;
    this.submitButtonTextTarget.classList.add('hidden');
    this.submitButtonTextTarget.classList.remove('flex');
    this.submitSpinnerTarget.classList.remove('hidden');
    
    // Start cycling through processing phrases
    this.cyclePhrases();
    
    // Simulate form submission
    this.formTarget.submit();
  }

  cyclePhrases() {
    const processingPhrases = [
      "Processing...",
      "Gathering meeting context...",
      "Analyzing conversation type...",
      "Considering participant dynamics...", 
      "Reviewing strategic objectives...",
      "Consulting conversation frameworks...",
      "Selecting relevant card decks...",
      "Curating guidance cards...",
      "Building conversation flow...",
      "Optimizing for outcomes...",
      "Preparing your personalized strategy...",
      "Preparing tricky discussion scenarios...",
      "Suggesting meeting wrapups...",
      "Polishing conversation structure...",
      "Double-checking strategic alignment...",
      "Adding finishing touches...",
      "Enhancing conversation clarity...",
      "Reviewing success patterns...",
      "Incorporating best practices...",
      "Finalizing your framework...",
      "Almost there..."
    ];
    
    let currentIndex = 0;
    this.processingTextTarget.textContent = processingPhrases[currentIndex];
    
    this.processingInterval = setInterval(() => {
      currentIndex = (currentIndex + 1) % processingPhrases.length;
      this.processingTextTarget.textContent = processingPhrases[currentIndex];
    }, 2500); // Change phrase every 2.5 seconds
  }

  disconnect() {
    if (this.processingInterval) {
      clearInterval(this.processingInterval);
    }
    // ... any other cleanup code ...
  }
} 