import { Controller } from "@hotwired/stimulus"
import React from 'react'
import { createRoot } from "react-dom/client"
import LinkedInMentionsCarousel from "../components/LinkedInMentionsCarousel"

export default class extends Controller {
  connect() {
    const root = createRoot(this.element)
    root.render(
      <LinkedInMentionsCarousel
        mentions={JSON.parse(this.element.dataset.mentions)}
      />
    )
  }
}
