import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const LinkedInMentionsCarousel = ({ mentions }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      arrows={true}
      containerClass="linkedin-mentions-carousel-container"
      autoPlay={true}
      autoPlaySpeed={5000}
    >
      {mentions.map((mention, index) => (
        <div key={index} className="carousel-item-wrapper px-0">
          <div className="flex flex-col sm:flex-row gap-4 bg-white rounded-3xl max-w-4xl flex-nowrap">
            <div className="sm:w-1_4 flex-shrink-0">
              <img
                className="w-full h-full object-cover rounded-t-3xl sm:rounded-t-none sm:rounded-l-3xl"
                src={mention.image_path}
                alt={mention.author}
                style={{ maxHeight: "100%" }}
              />
            </div>
            <div className="flex flex-col gap-4 p-3 sm:p-4 sm:w-3_4 justify-center">
              <p className="text-conv-gray-charcoal text-lg overflow-hidden">
                {mention.quote}
              </p>
              <div className="flex flex-row gap-x-4">
                <div className="flex flex-col">
                  <a 
                    href={mention.linkedin_url}
                    target="_blank"
                    className="flex flex-row justify-start items-center gap-x-2 hover:opacity-80 transition-opacity"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-linkedin"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                    </svg>
                    <p className="text-conv-gray-charcoal font-bold text-lg">
                      {mention.author}
                    </p>
                  </a>
                  <p className="text-conv-gray-light text-sm">
                    {mention.job_title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default LinkedInMentionsCarousel;
