import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["answer", "icon", "card"]

  toggle(event) {
    const answer = event.currentTarget.querySelector('[data-faq-target="answer"]')
    const icon = event.currentTarget.querySelector('[data-faq-target="icon"]')
    
    if (answer.style.maxHeight === "0px") {
      answer.style.maxHeight = `${answer.scrollHeight}px`
      // Change to minus icon
      icon.innerHTML = `
        <path d="M5.5 12H19.5" stroke="#F8D4CF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      `
    } else {
      answer.style.maxHeight = "0px"
      // Change back to plus icon
      icon.innerHTML = `
        <path d="M12.5 5V19" stroke="#F8D4CF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
        <path d="M5.5 12H19.5" stroke="#F8D4CF" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
      `
    }
  }
}
