import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "content", "item"]

  connect() {
    // Initialize accordion state
    this.isOpen = false
  }

  toggle(event) {
    event.preventDefault()
    this.isOpen = !this.isOpen
    
    // Toggle content visibility
    this.contentTarget.classList.toggle("hidden")
    
    // Rotate arrow icon
    const icon = this.buttonTarget.querySelector("[data-accordion-icon]")
    icon.classList.toggle("rotate-180")
  }
} 