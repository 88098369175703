import { Controller } from "@hotwired/stimulus";
import meetingTypesData from "../assets/meeting_types.json";

const ANIMATION_DURATION = 300;

export default class MultiStepFormController extends Controller {
  static targets = ["step", "progressBar", "form", "meetingTypeContainer", "submitButton", "submitButtonText", "submitSpinner", "processingText"];
  static values = {
    currentStep: Number,
    totalSteps: Number,
  };

  processingPhrases = [
    "Processing...",
    "Gathering meeting context...",
    "Analyzing conversation type...",
    "Considering participant dynamics...", 
    "Reviewing strategic objectives...",
    "Consulting conversation frameworks...",
    "Selecting relevant card decks...",
    "Curating guidance cards...",
    "Building conversation flow...",
    "Optimizing for outcomes...",
    "Preparing your personalized strategy...",
    "Preparing tricky discussion scenarios...",
    "Suggesting meeting wrapups...",
    "Polishing conversation structure...",
    "Double-checking strategic alignment...",
    "Adding finishing touches...",
    "Enhancing conversation clarity...",
    "Reviewing success patterns...",
    "Incorporating best practices...",
    "Finalizing your framework...",
    "Almost there..."
  ]
  processingInterval = null

  connect() {
    console.log("Form controller connected");
    this.initializeForm();
  }

  initializeForm() {
    this.currentStepValue = 1;
    this.stepIndex = 0;
    this.formData = {};
    this.meetingTypes = meetingTypesData.meetingTypes;
    this.steps = Array.from(this.stepTargets);
    this.totalStepsValue = this.steps.length;
    this.showCurrentStep();
    console.log("Initial steps:", this.steps);
  }

  handleSelection(event) {
    const { name: field, value } = event.target;
    console.log("Selection made:", field, value);
    this.formData[field] = value;

    switch (field) {
      case "briefing_type":
        this.progressToNext();
        break;
      case "meeting_with":
        this.updateMeetingTypes(value);
        this.progressToNext();
        break;
      case "meeting_type":
        this.handleMeetingTypeSelection(this.formData.meeting_with, value);
        break;
      default:
        this.progressToNext();
    }
  }

  handleMeetingTypeSelection(meetingWith, meetingType) {
    console.log("Handling meeting type selection:", {
      meetingWith,
      meetingType,
    });

    // Get context toggles for this meeting type
    const contextToggles = this.getContextToggles(meetingWith, meetingType);
    console.log("Context toggles:", contextToggles);

    // Create new steps for context toggles
    this.updateStepsWithContextToggles(contextToggles);

    // Important: Move to the next step after updating
    requestAnimationFrame(() => {
      this.progressToNext();
    });
  }

  getContextToggles(meetingWith, meetingType) {
    return this.meetingTypes[meetingWith]?.[meetingType]?.contextToggles || {};
  }

  updateStepsWithContextToggles(contextToggles) {
    console.log("Updating steps with context toggles");

    // Find the meeting type step and submit step
    const meetingTypeStep = this.steps.find(
      (step) => step.dataset.stepName === "meeting_type"
    );
    const submitStep = this.steps.find((step) =>
      step.classList.contains("submit-step")
    );
    const meetingTypeIndex = this.steps.indexOf(meetingTypeStep);

    // Remove any existing context toggle steps
    this.steps = this.steps.filter(
      (step) => !step.classList.contains("context-toggle-step")
    );
    const existingToggles = document.querySelectorAll(".context-toggle-step");
    existingToggles.forEach((step) => step.remove());

    // Create and insert new context toggle steps
    const stepsContainer = this.stepTargets[0].parentNode;
    const newSteps = [];

    Object.entries(contextToggles).forEach(([key, data]) => {
      const step = this.createContextToggleStep(key, data);
      stepsContainer.insertBefore(step, submitStep);
      newSteps.push(step);
    });

    // Update steps array
    this.steps = [
      ...this.steps.slice(0, meetingTypeIndex + 1),
      ...newSteps,
      submitStep,
    ];

    // Update total steps
    this.totalStepsValue = this.steps.length;
    console.log("Updated steps:", this.steps);
  }

  createContextToggleStep(key, data) {
    const step = document.createElement("div");
    step.classList.add(
      "step",
      "context-toggle-step",
      "hidden", 
      "opacity-0",
      "transition-opacity",
      "duration-300",
      "flex-grow",
      "flex",
      "flex-col",
      "justify-between"
    );
    step.dataset.multiStepFormTarget = "step";
    step.dataset.stepName = key;

    step.innerHTML = `
      <div class="mb-6 flex-grow flex flex-col justify-between">
      <div>  
        <div class="my-6 w-full flex flex-row justify-end sm:justify-between items-center">
          <span class="hidden sm:flex text-conv-slate-mist">Let's get some quick context...</span>
          <button type="button" data-action="click->multi-step-form#skipContextToggle"
            class="px-4 py-2 rounded-xl bg-white text-conv-slate-mist active:bg-conv-gray-ash active:scale-95 active:shadow-inner hover:bg-conv-gray-ash transition-all duration-150 touch-none select-none"
              style="background-color: rgba(255, 255, 255, 0.5)">Skip</button>
          </div>
          <h2 class="text-3xl font-bold mb-4">${data.question}</h3>
        </div>
        <div class="grid grid-cols-2 gap-3">
          ${data.options
            .map(
              (option) => `
            <div class="flex flex-col justify-center items-center gap-4">
              <input class="peer hidden" type="radio" name="${key}" 
                     value="${option.toLowerCase().replace(/\s+/g, "_")}" 
                     id="${key}_${option.toLowerCase().replace(/\s+/g, "_")}"
                     data-action="change->multi-step-form#handleSelection">
              <label class="w-full flex flex-row items-center justify-center gap-2 cursor-pointer select-none rounded-xl px-2 py-4 bg-white text-left text-base gradient-form-label"
                     for="${key}_${option.toLowerCase().replace(/\s+/g, "_")}">
                ${option}
              </label>
            </div>
          `
            )
            .join("")}
        </div>
      </div>
    `;

    return step;
  }

  progressToNext() {
    console.log("Progressing to next step:", {
      current: this.currentStepValue,
      total: this.totalStepsValue,
    });

    if (this.currentStepValue < this.totalStepsValue) {
      this.fadeOut(this.currentStepElement, () => {
        this.currentStepValue++;
        this.stepIndex++;
        this.fadeIn(this.currentStepElement);
        this.updateProgressBar();
      });
    }
  }

  previous(event) {
    event.preventDefault();
    if (this.currentStepValue > 1) {
      this.fadeOut(this.currentStepElement, () => {
        this.currentStepValue--;
        this.stepIndex--;
        this.fadeIn(this.currentStepElement);
        this.updateProgressBar();
      });
    }
  }

  updateMeetingTypes(meetingWith) {
    console.log("Updating meeting types for:", meetingWith);
    const meetingTypeOptions = this.meetingTypes[meetingWith] || {};
    const container = this.meetingTypeContainerTarget;

    container.innerHTML = Object.entries(meetingTypeOptions)
      .map(
        ([key, data]) => `
      <div class="flex flex-col items-center justify-center gap-4">
        <input class="peer hidden" type="radio" name="meeting_type" 
               value="${key}" id="meeting_type_${key}"
               data-action="change->multi-step-form#handleSelection">
        <label class="w-full flex flex-row items-center justify-center gap-2 cursor-pointer select-none rounded-xl p-2 py-4 bg-white text-left text-base gradient-form-label"
               for="meeting_type_${key}">
          ${data.label}
        </label>
      </div>
    `
      )
      .join("");
  }

  skipContextToggle(event) {
    event.preventDefault();
    const currentToggle = event.target.closest(".context-toggle-step");
    const fieldName = currentToggle.dataset.stepName;

    if (fieldName) {
      this.formData[fieldName] = null;
      this.progressToNext();
    }
  }

  submit(event) {
    event.preventDefault();
    
    // Disable submit button and show spinner
    this.submitButtonTarget.disabled = true;
    this.submitButtonTextTarget.classList.add('hidden');
    this.submitButtonTextTarget.classList.remove('flex');
    this.submitSpinnerTarget.classList.remove('hidden');
    
    const userBrief = {
      meeting_with: this.formData.meeting_with,
      meeting_type: this.formData.meeting_type,
      ...Object.fromEntries(
        Object.entries(this.formData).filter(
          ([key]) => !["meeting_with", "meeting_type"].includes(key)
        )
      ),
    };

    this.formTarget.querySelector("#conversation_user_brief").value =
      JSON.stringify(userBrief);
    this.formTarget.querySelector(
      "#conversation_title"
    ).value = `Conversation created at ${new Date().toLocaleString()}`;
    this.formTarget.submit();

    this.cyclePhrases()
  }

  showCurrentStep() {
    if (!this.currentStepElement) return;

    this.steps.forEach((step) => {
      step.classList.add("opacity-0", "hidden");
    });

    this.currentStepElement.classList.remove("hidden");
    requestAnimationFrame(() => {
      this.currentStepElement.classList.remove("opacity-0");
    });

    this.updateProgressBar();
  }

  updateProgressBar() {
    const progress = (this.currentStepValue / this.totalStepsValue) * 100;
    this.progressBarTarget.style.width = `${progress}%`;
  }

  fadeOut(element, callback) {
    element.classList.add("opacity-0");
    setTimeout(() => {
      element.classList.add("hidden");
      callback();
    }, ANIMATION_DURATION);
  }

  fadeIn(element) {
    element.classList.remove("hidden");
    requestAnimationFrame(() => {
      element.classList.remove("opacity-0");
    });
  }

  get currentStepElement() {
    return this.steps[this.stepIndex];
  }

  cyclePhrases() {
    let currentIndex = 0
    this.processingTextTarget.textContent = this.processingPhrases[currentIndex]
    
    this.processingInterval = setInterval(() => {
      currentIndex = (currentIndex + 1) % this.processingPhrases.length
      this.processingTextTarget.textContent = this.processingPhrases[currentIndex]
    }, 2500) // Change phrase every 2.5 seconds
  }

  disconnect() {
    if (this.processingInterval) {
      clearInterval(this.processingInterval)
    }
    // ... any other cleanup code ...
  }
}
